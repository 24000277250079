<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('整改单状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="整改单标题" :label="detail.iname"/>
        <ys-cell title="项目名称" :label="detail.projectName"/>
        <ys-cell title="服务单位" :label="detail.serverOrgName"/>
        <ys-cell title="备注" :label="detail.riskRemarks"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" :key="detail.id">
          <Tab-pane label="风险列表" icon="md-list-box" name="1">
            <problems :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='1'"></problems>
          </Tab-pane>
          <Tab-pane label="操作记录" icon="ios-list-box-outline" name="2">
            <check-list :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='2'"></check-list>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">风险分析</h1>
        <chart :key="detail.id" :mid="detail.checkId"></chart>
      </template>
    </ys-detail>
    <ys-modal v-model="modShow" :width="1000" title="修改整改单">
      <Edit @on-ok="getDetail" :mid="detailID"></Edit>
    </ys-modal>
    <ys-modal v-model="downShow" :width="400" title="下载整改建议书">
      <downFile
        @on-ok="getDetail"
        :type="2"
        :checkId="detail.checkId"
        :serveOrgId="detail.serverOrgId">
      </downFile>
    </ys-modal>
    <ys-modal v-model="checkStatusFlag" :width="500" title="审批整改单">
      <checkStatus @on-ok="getDetail" :mid="detailID"></checkStatus>
    </ys-modal>
    <ys-drawer v-model="checkFlag">
      <check-list :mid="detailID"></check-list>
    </ys-drawer>
  </div>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {amendInfo, amendCommit} from "@/api/amend";
import {getProblemsList} from "@/api/problem";
import downFile from "@/views/rectifications/downFile";
import checkStatus from "@/views/rectifications/checkStatus";
import CheckList from "@/views/rectifications/detail/checkList";
import problems from "@/views/rectifications/detail/problem";
import Chart from "@/views/task/detail/chart";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    problems,
    Chart,
    'Edit': () => import('./Edit.vue'),
    downFile,
    checkStatus,
    CheckList
  },
  data() {
    return {
      collapseValue: [],
      commentList: [],
      modShow: false,
      problemFlag: false,
      checkFlag: false,
      checkStatusFlag: false,
      downShow: false,
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      getProblemsList({amendId: this.detailID,limit:-1,noPending: true}).then((res) => {
        this.commentList = res.data.list || []
        this.collapseValue=[];
        this.commentList.forEach((item, index) => {
          if (item.status === 2) this.collapseValue.push(index.toString())
        })
      })
      amendInfo({id: this.detailID}).then((res) => {
        if (res.code === 200) {
          this.detail = res.data;
          this.setTagName(this.detail.iname);
        }
        this.loading = false;
      })
    },
    commit() {
      this.$Modal.confirm({
        title: '提示',
        content: '请确认项目阶段、五牌一图单体建筑物数据是否更新，提交后将无法修改报告信息！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          amendCommit({id: this.detail.id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '操作成功！'
              });
              this.getDetail();
            }
          });
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.commentDetail {
  display: flex;

  .baseInfo {
    width: 55%;
  }

  .rebackList {
    max-height: 500px;
    overflow-y: auto;
    flex: 1;
  }
}

.status {
  position: absolute;
  right: 0;
  top: -1px;
}

.picList {
  padding: 10px;
}

.content {
  line-height: 20px;

  .time {
    padding: 5px 0;
  }
}

.reback {
  margin: 10px;
  padding: 10px;

  p {
    line-height: 24px;
    padding: 5px;
    font-size: 14px;
  }

}
</style>