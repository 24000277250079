<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="模板名称" prop="templateId">
        <Select v-model="form.templateId" placeholder="模板名称" transfer>
          <Option v-for="(item) in templateList" :value="item.id" :key="item.id">{{ item.name }}-{{ item.orgName }}</Option>
        </Select>
      </FormItem>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="putOut">下载</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {templateList} from "@/api/report";
import {blobDownFile} from 'ys-admin'
import {checkInfo} from "@/api/check";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    type: {type: Number, default: 1},
    serveOrgId: {type: Number, default: null},
    checkId: {type: Number, default: null},
    mid: {type: Number, default: null},
    name: {type: String, default: null},
  },
  data() {
    return {
      ruleValidate: {
        templateId: [
          {required: true, type: 'number', message: '请填选择模板', trigger: 'change'}
        ],
      },
      templateList: [],
      form: {
        checkId: '',
        templateId: '',
      },
    }
  },
  mounted() {
    this.form.checkId = this.checkId;
    this.init();
  },
  methods: {
    checkDesc() {
      if (this.form.desc) {
        this.$Modal.confirm({
          title: '提示',
          content: '检查结论已经存在，是否要覆盖？',
          okText: '覆盖',
          cancelText: '取消',
          onOk: () => {
            this.mode()
          }
        })
      }
    },
    putOut() {
      this.modal.spinShow = true
      this.$axios({
        method: 'GET',
        responseType: 'blob',
        url: 'report/Gen?checkId=' + this.checkId + '&templateId=' + this.form.templateId,
      }).then((res) => {
        this.close()
        this.modal.spinShow = false;
        blobDownFile(res.data, (this.name || this.$formatDate(new Date(), 'yyyy-MM-dd')) + '的整改建议书.docx');
        this.$emit('on-ok');
        this.modal.spinShow = false
      }).catch(() => {
        this.close()
        this.modal.spinShow = false
      });
    },
    init() {
      checkInfo({id: this.mid}).then((checkInfo) => {
        if (checkInfo.code === 200) {
          templateList({orgId: this.serveOrgId, standardId: checkInfo.warehouseId, type: this.type, limit: -1, status: 1}).then((res) => {
            this.templateList = res.data.list || [];
            this.form.templateId = this.templateList.length > 0 ? this.templateList[0].id : '';
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>
